import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import { ContentfulTypes } from "common/types"
import Button, { ButtonBase } from "components/button"
import { H5, P } from "components/headings"
import Link from "components/Link"
import Media from "components/Media"
import * as React from "react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { Filter } from "components/ReferencesSection"
import { filterStyles } from "pages/articles"
import { PageReference } from "./types"

interface ICardProp {
	card?: boolean
}

const ReferenceContainer = styled(Link)<ICardProp>`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	text-decoration: none;
	margin: 12px 12px 64px;

	${({ card }) =>
		card &&
		css`
			padding: 40px 20px;
			margin: 12px;
			box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
			transition: box-shadow 0.1s ease-in-out;
			border-radius: 8px;

			${mediaBreakpoint(BreakPoints.MD)} {
				padding: 60px 50px;
			}
		`}

	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 12px 12px 80px;
	}

	${ButtonBase} {
		width: initial;
	}

	&::not(:first-of-type) {
		${({ card }) =>
		!card &&
			css`
				margin-top: 4rem;
			`}

		${mediaBreakpoint(BreakPoints.SM)} {
			margin-top: 12px;
		}
	}

	&:hover {
		${({ card }) =>
		card &&
			css`
				box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.25);

				button[data-variant="secondary"] {
					background-color: ${theme.color.darkPurple};
					color: ${theme.color.white};
				}
			`}

		${H5} {
			text-decoration: underline;
		}
	}
`

const ReferenceCategory = styled.div`
	color: var(--main-color);
	font-size: 1.2rem;
	font-weight: 800;
	text-transform: uppercase;
	margin: 0 0 8px;
`

const ReferenceTop = styled.div<ICardProp>`
	${({ card }) =>
		card &&
		css`
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 30px;
		`}
`

const ReferenceMediaContainer = styled.div<ICardProp>`
	${({ card }) =>
		card
			? css`
					height: 150px;
					width: 150px;

					${mediaBreakpoint(BreakPoints.MD)} {
						height: 250px;
						width: 250px;
					}
			  `
			: css`
					margin: 0 0 32px;
					border-radius: 8px;
					overflow: hidden;
					box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
					transition: box-shadow 0.1s ease-in-out;
					border-radius: 8px;

			  `}
`

const ReferenceBottom = styled.div<ICardProp>`
	${({ card }) =>
		card 
			? css`
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			align-items: center;

			p {
				text-align: center;
				font-size: 1.6rem;
				line-height: 2.4rem;
				color: ${theme.color.black};
			}

			button {
				width: fit-content !important;
				flex-shrink: 0;
			}
		`
			: css`
		p {
			text-align: left;
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
		h1 {
			font-size: 20px;
			font-weight: 800;
		}
		button {
			padding-bottom: 2rem;
		}
		`}
`

const Title = styled.h3`
	font-size: 2.8rem;
	line-height: 3.8rem;
	color: ${theme.color.black};
	text-align: left!important;
	font-weight: 800;
`

const Description = styled.div`
	height: 100%;
`
const ReferenceTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${theme.spacing(1.5)};
`
const customFilterStyles = css`
	padding: 5px 20px !important;
	margin: 0 ${theme.spacing(2)} ${theme.spacing(1)} 0 !important;
	height: fit-content !important;
`

const Reference: React.FC<PageReference> = ({
	heroImage,
	referenceImage,
	referenceTitle,
	description,
	slug,
	referencesStyle,
	category,
	longTitle,
	title,
	tags,
	__typename,
}) => {
	const finalTitle = referenceTitle || (longTitle ? longTitle.title : title)
	const isCard = referencesStyle === "card"
	const path = React.useMemo(() => {
		if (__typename === ContentfulTypes.BlogPost) {
			return `post/${slug}/`
		}
		if (__typename === ContentfulTypes.KnowledgePost) {
			return `knowledge-center/${slug}/`
		}

		return `${slug}/`
	}, [__typename, slug])

	return (
		<ReferenceContainer to={`/${path}`} card={isCard} data-element="reference-container">
			{referenceImage || heroImage ? (
				<ReferenceTop card={isCard}>
					<ReferenceMediaContainer card={isCard}>
						{referenceImage && isCard ? <Media {...referenceImage} /> : <Media {...heroImage} width={670}/>}
					</ReferenceMediaContainer>
				</ReferenceTop>
			) : null}
			<ReferenceTags data-element="ReferenceTags">
				{ !isCard && tags && tags.slice(0, 2).map(tag => {
					const { value, color, textColor, label } = tag
					return (
						<Filter
							key={value}
							css={[filterStyles, customFilterStyles]}
							color={color}
							textColor={textColor}
							data-element="filter"
						>
							{label}
						</Filter>
					)
				})}
			</ReferenceTags>
			<ReferenceBottom card={isCard}>
				{category && isCard ?<ReferenceCategory>{category}</ReferenceCategory>: null }
				{referenceTitle ? <Title>{finalTitle}</Title> : <H5>{finalTitle}</H5>}
				{description && isCard ? (
					<Description>
						<p>{description.description}</p>
					</Description>
				) : (
					description && <P data-element="reference-description">{description.description}</P>
				)}
				{isCard ?
					<Button label="Learn More" type="button" variant= "secondary" /> : null }
			</ReferenceBottom>
		</ReferenceContainer>
	)
}

export default React.memo(Reference)
